<template>
  <div>
    <h2>Отход производства</h2>

    <h2>Информация об отходе</h2>
    <el-form
      v-if="organizationWaste.organizationInfo.id !== null"
      :model="editWasteProductionForm"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Код отхода">
            <el-input
              disabled
              v-model="editWasteProductionForm.ss01WasteDictionaryDTO.wasteCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Наименование отхода">
            <el-input
              disabled
              v-model="editWasteProductionForm.ss01WasteDictionaryDTO.wasteName"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Степень опасности">
            <el-select
              clearable
              :disabled="typeWriteDangerDegree"
              class="searchPole"
              v-model="
                editWasteProductionForm.organizationWastePSS01DangerDegreeInfo
              "
              filterable
              value-key="id"
              placeholder="Степень опасности"
            >
              <el-option
                v-for="item in dangerDegree"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Координаты мест образования отходов на «Карте-схеме источников образования отходов»"
          >
            <el-input
              placeholder="Координаты"
              v-model="editWasteProductionForm.coordinates"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="Код вида отхода по классификации Базельской конвенции"
          >
            <el-input
              disabled
              v-model="
                editWasteProductionForm.ss01WasteDictionaryDTO
                  .bazelConvinceWasteCode
              "
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Код отхода по [3]">
            <el-input
              disabled
              v-model="
                editWasteProductionForm.ss01WasteDictionaryDTO
                  .wasteCodeByDecision2000
              "
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="Код физического состояния отхода">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss16PhysicalInfo"
              filterable
              placeholder="Выбрать"
            >
              <el-option
                v-for="item in pss16PhysicalInfoList"
                :key="item.id"
                :label="item.id + '-' + item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="Вторичный материальный ресурс "
            prop="pss04SecondaryInfo"
          >
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss04SecondaryInfo"
              filterable
              placeholder="Выбрать"
            >
              <el-option
                v-for="item in pss04SecondaryInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label=" Ртутьсодержащие" prop="pss05MercuryInfo">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss05MercuryInfo"
              filterable
              placeholder="Выбрать"
            >
              <el-option
                v-for="item in pss05MercuryInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label=" ПХБ" prop="">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.phb"
              filterable
              placeholder="Выбрать"
            >
              <el-option
                v-for="item in pss05MercuryInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="ТБО ">
            <el-switch
              v-model="editWasteProductionForm.tbo"
              active-text="Да"
              :active-value="true"
              :inactive-value="false"
              inactive-text="Нет"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Норматив образования отхода">
            <el-input
              placeholder="Норматив образования отхода"
              v-model="editWasteProductionForm.wasteGenerationStandardMeasure"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="ед. измерения">
            <el-input
              placeholder="ед. измерения"
              v-model="editWasteProductionForm.wasteGenerationStandard"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            label="Физико-химическая характеристика отходов, состав отходов по компонентам, наименование"
          >
            <el-input
              placeholder="Физико-химическая характеристика отходов, состав отходов по компонентам, наименование"
              v-model="editWasteProductionForm.physChemCharacteristicName"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Физико-химическая характеристика отходов, состав отходов по компонентам, содержание, %"
          >
            <el-input
              placeholder="Физико-химическая характеристика отходов, состав отходов по компонентам, содержание, %"
              v-model="editWasteProductionForm.physChemCharacteristicPercent"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Опасные свойства отхода</h4>

      <el-form-item label="">
        <el-select
          clearable
          class="searchPole"
          value-key="id"
          v-model="editWasteProductionForm.pss03DangerInfo"
          filterable
          placeholder="Опасные свойства"
        >
          <el-option
            v-for="item in pss03DangerInfoList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Единицы измерения">
        <el-select
          class="searchPole"
          value-key="id"
          v-model="editWasteProductionForm.pss93UnitsInfo"
          filterable
          placeholder="Единицы измерения"
        >
          <el-option
            v-for="item in pss93UnitsInfoList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-row :gutter="10">
        <el-form-item
          label="Количество образующихся отходов производства, подлежащих хранению"
        >
          <el-col :span="18">
            <el-input
              placeholder="Количество образующихся отходов производства, подлежащих хранению"
              v-model="editWasteProductionForm.storeLimit"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="editWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
          <el-col :span="2">
            <input type="checkbox" id="checkboxstoreLimitYear" v-model="editWasteProductionForm.storeLimitYear">
            <label for="checkboxstoreLimitYear">Годовой лимит</label>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row :gutter="10">
        <el-form-item
          label="Количество образующихся отходов производства, подлежащих захоронению"
        >
          <el-col :span="20">
            <el-input
              placeholder="Количество образующихся отходов производства, подлежащих захоронению"
              v-model="editWasteProductionForm.burialLimit"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="editWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row :gutter="10">
        <el-form-item
          label="Количество отходов производства, хранимых для вывоза одной транспортной единицей"
        >
          <el-col :span="20">
            <el-input
              placeholder="Количество отходов производства, хранимых для вывоза одной транспортной единицей"
              v-model="editWasteProductionForm.transferUnitAmount"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="editWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-row>

      <h4>Данные для первого входа</h4>

      <el-row :gutter="10">
        <el-form-item label="Хранилось на начало года">
          <el-col :span="8">
            <el-input
              placeholder="Хранилось на начало года"
              v-model="editWasteProductionForm.storedForTheBeginningOfYear"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="editWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="Образовано">
          <el-col :span="8">
            <el-input
              placeholder="Образовано"
              v-model="editWasteProductionForm.formed"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              disabled
              placeholder="Выберите ед. измерения"
              v-model="editWasteProductionForm.pss93UnitsInfo.name"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row :gutter="10"> </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Поступило">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Поступило"
                  @change="rules.pss17ReceiptReasonInfo[0].required = changeValueRules(editWasteProductionForm.received)"
                  v-model="editWasteProductionForm.received"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="pss17ReceiptReasonInfo" label="Наименование причины поступления" >
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss17ReceiptReasonInfo"
              filterable
              placeholder="Наименование причины поступления"
            >
              <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Поступило 2">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Поступило 2"
                  v-model="editWasteProductionForm.received2"
                  @change="rules.pss17ReceiptReasonInfo2[0].required = changeValueRules(editWasteProductionForm.received2)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="pss17ReceiptReasonInfo2" label="Наименование причины поступления2" >
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss17ReceiptReasonInfo2"
              filterable
              placeholder="Наименование причины"
            >
              <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Из них по импорту">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Из них по импорту"
                  v-model="editWasteProductionForm.receivedimp"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Поступило от физ. лиц.">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Поступило от физ. лиц."
                  v-model="editWasteProductionForm.receivedfz"
                  @change="rules.pss17ReceiptReasonInfofz[0].required = changeValueRules(editWasteProductionForm.receivedfz)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item   prop="pss17ReceiptReasonInfofz" label="Наименование причины поступления от физ. лиц.">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss17ReceiptReasonInfofz"
              filterable
              placeholder="Наименование причины"
            >
              <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Передано">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Передано"
                  v-model="editWasteProductionForm.transferred"
                  @change="rules.pss18TransferReasonInfo[0].required = changeValueRules(editWasteProductionForm.transferred)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item  prop="pss18TransferReasonInfo" label="Причина операции передачи">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              placeholder="Причина передачи"
              v-model="editWasteProductionForm.pss18TransferReasonInfo"
              @change="rules.pss21TransferObjectInfo[0].required = editWasteProductionForm.pss18TransferReasonInfo == null ? false : (editWasteProductionForm.pss18TransferReasonInfo.id == 6 ? true : false)"
              filterable
            >
              <el-option
                v-for="item in pss18TransferReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item prop="pss21TransferObjectInfo" label="Объект захоронения">
            <el-select
              :disabled="editWasteProductionForm.pss18TransferReasonInfo == null ? false : (editWasteProductionForm.pss18TransferReasonInfo.id!=6)"
              clearable
              placeholder="Объект захоронения"
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss21TransferObjectInfo"
              filterable
            >
              <el-option
                v-for="item in pss21BurialObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Передано по экспорту">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Передано по экспорту"
                  v-model="editWasteProductionForm.transferredexp"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Реализовано физ. лицам">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Реализовано физ. лицам"
                  v-model="editWasteProductionForm.transferredfz"
                  @change="rules.pss18TransferReasonInfofz[0].required = changeValueRules(editWasteProductionForm.transferredfz)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item  prop="pss18TransferReasonInfofz" label="Причина операции передачи">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              placeholder="Причина передачи"
              v-model="editWasteProductionForm.pss18TransferReasonInfofz"
              filterable
            >
              <el-option
                v-for="item in pss18TransferReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Использовано">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Использовано"
                  v-model="editWasteProductionForm.used"
                  @change="rules.pss19DirectionOfUseInfo[0].required = changeValueRules(editWasteProductionForm.used)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="pss19DirectionOfUseInfo" label="Наименование направления использования">
            <el-select
              clearable
              placeholder="Наименование направления"
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss19DirectionOfUseInfo"
              filterable
            >
              <el-option
                v-for="item in pss19DirectionOfUseInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Обезврежено">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Обезврежено"
                  v-model="editWasteProductionForm.neutralized"
                  @change="rules.pss20NeutralizeMethodInfo[0].required = changeValueRules(editWasteProductionForm.neutralized)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="pss20NeutralizeMethodInfo" label="Наименование способа обезвреживания">
            <el-select
              clearable
              placeholder="Наименование способа"
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss20NeutralizeMethodInfo"
              filterable
            >
              <el-option
                v-for="item in pss20NeutralizeMethodInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Захоронено">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Захоронено"
                  v-model="editWasteProductionForm.buried"
                  @change="rules.pss21BurialObjectInfo[0].required = changeValueRules(editWasteProductionForm.buried)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="pss21BurialObjectInfo" label="Объект захоронения">
            <el-select
              clearable
              placeholder="Объект захоронения"
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss21BurialObjectInfo"
              filterable
            >
              <el-option
                v-for="item in pss21BurialObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="16">
          <el-form-item label="Направлено на объекты хранения">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-input
                  placeholder="Направлено на объекты хранения"
                  v-model="editWasteProductionForm.sentForStorage"
                  @change="rules.pss22StoredObjectInfo[0].required = changeValueRules(editWasteProductionForm.sentForStorage)"
                ></el-input>
              </el-col>
              <el-col :span="6">
                <el-input
                  disabled
                  placeholder="Выберите ед. измерения"
                  v-model="editWasteProductionForm.pss93UnitsInfo.name"
                ></el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item prop="pss22StoredObjectInfo" label="Наименование объекта хранения">
            <el-select
              clearable
              placeholder="Наименование объекта"
              class="searchPole"
              value-key="id"
              v-model="editWasteProductionForm.pss22StoredObjectInfo"
              filterable
            >
              <el-option
                v-for="item in pss22StoredObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="organizationInfo"
            label="Объект / Филиал / Подразделение"
          >
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="editWasteProductionForm.organizationInfo"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in organizationHierarchyByType"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row >
        <el-date-picker 
                  v-model="editWasteProductionForm.dateFrom"
                  type="date"
                  placeholder = "Дата начала работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <br>
      <el-row>
        <input type="checkbox" id="checkboxdatEd" v-model="editWasteProductionForm.datEd">
        <label for="checkboxdatEd">Устаревшее</label>
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="editWasteProductionForm.dateTo"
                  type="date"
                  :disabled="!editWasteProductionForm.datEd"
                  placeholder = "Дата окончания работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <el-row>
        <el-col>
          <input type="checkbox" id="checkboxremoveFromAir" v-model="editWasteProductionForm.removeFromAir">
          <label for="checkboxremoveFromAir">Не отображать отход в отчете 1-отходы</label>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="24">
          <el-button 
          @click="addWaste"
           type="primary"
           :disabled="prod==1" 
           >Сохранить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
import notification from "@/mixins/notification";

export default {
  name: "wasteproductionAdd",
  mixins: [notification],
  data() {
    return {
      prod:0,
      user_id: null,
      userblock :[ '16','17','19','90','92','93','96','180', '232','128','156','157', '160', '187','189','186','184','188','190','192','240','243','246','251','265','291','303','305','321','350','364','367','368'],
      rules: {
        pss04SecondaryInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        pss05MercuryInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss17ReceiptReasonInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss17ReceiptReasonInfo2: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss17ReceiptReasonInfofz: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss18TransferReasonInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss21TransferObjectInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss18TransferReasonInfofz: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss19DirectionOfUseInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss20NeutralizeMethodInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss21BurialObjectInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        pss22StoredObjectInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],   

        organizationInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
      typeWriteDangerDegree: false,
      pss16PhysicalInfoList: null,
      pss04SecondaryInfoList: null,
      pss05MercuryInfoList: null,
      pss93UnitsInfoList: null,
      pss17ReceiptReasonInfoList: null,
      pss18TransferReasonInfoList: null,
      pss19DirectionOfUseInfoList: null,
      pss20NeutralizeMethodInfoList: null,
      pss21BurialObjectInfoList: null,
      pss22StoredObjectInfoList: null,
      pss03DangerInfoList: null,
      dangerDegree: null,
      
      search: {
        code: null,
        name: null,
        loadingCodes: false,
        loadingNames: false,
      },
      editWasteProductionForm: {
        phb:null,
        datEd:false,
        dateFrom:null,
        dateTo:null,
        removeFromAir: false,
        storeLimitYear: false,
        organizationInfo: {
          id: null,
          name: null,
        },
        ss01WasteDictionaryDTO: {
          dangerDegreeClassifierDTO: {
            id: null,
            name: null,
          },
        },
        pss16PhysicalInfo: {
          id: null,
          name: null,
        },
        pss04SecondaryInfo: null,
        pss05MercuryInfo: null,
        tbo: false,
        wasteGenerationStandardMeasure: null,
        wasteGenerationStandard: null,
        physChemCharacteristicName: null,
        physChemCharacteristicPercent: null,
        organizationWastePSS01DangerDegreeInfo: {
          id: null,
          name: null,
        }, 
        pss03DangerInfo: {
          id: null,
          name: null,
        },
        storeLimit: null,
        burialLimit: null,
        transferUnitAmount: null,
        storedForTheBeginningOfYear: null,
        formed: null,
        received: null,
        transferred: null,
        transferredfz: null,
        neutralized: null,
        buried: null,
        sentForStorage: null,
        pss17ReceiptReasonInfo: {
          id: null,
          name: null,
        },
        pss17ReceiptReasonInfo2: {
          id: null,
          name: null,
        },
        pss17ReceiptReasonInfofz: {
          id: null,
          name: null,
        },
        pss18TransferReasonInfo: {
          id: null,
          name: null,
        },
        pss18TransferReasonInfofz: {
          id: null,
          name: null,
        },
        pss19DirectionOfUseInfo: {
          id: null,
          name: null,
        },
        pss20NeutralizeMethodInfo: {
          id: null,
          name: null,
        },
        pss21BurialObjectInfo: {
          id: null,
          name: null,
        },
        pss21TransferObjectInfo:{
          id: null,
          name: null,
        },
        pss22StoredObjectInfo: {
          id: null,
          name: null,
        },
        pss93UnitsInfo: {
          id: null,
          name: null,
        },
      },
      code: null,
      name: null,
      dangerClass: null,
      organizationalUnits: null,
    };
  },

  computed: {
    ...mapGetters({
      ss01CodeList: "GETSSS01CODELIST",
      wasteNames: "GETWASTENAMES",
      foundedWaste: "GETSS01DICTIONARY",
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
      organizationWaste: "GETORGANIZATIONWASTE",
    }),
  },

  methods: {
    changeValueRules(value){
      console.log(value);
      if (value == "" || value == null)
      return false;
      else 
      return true;
    },
    async getOrganizationWaste() {
      await this.$store.dispatch(
        "getOrganizationWaste",
        this.$route.params.organizationWasteId
      );
    },

    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 2,
      });
    },

    getPss16PhysicalInfoList() {
      this.getSubClassifierById(16).then((response) => {
        this.pss16PhysicalInfoList = response.data.content;
      });
    },

    getPss04SecondaryInfoList() {
      this.getSubClassifierById(4).then((response) => {
        this.pss04SecondaryInfoList = response.data.content;
      });
    },

    getPss05MercuryInfoList() {
      this.getSubClassifierById(5).then((response) => {
        this.pss05MercuryInfoList = response.data.content;
      });
    },

    getPss93UnitsInfoList() {
      this.getSubClassifierById(93).then((response) => {
        this.pss93UnitsInfoList = response.data.content;
      });
    },

    getPss17ReceiptReasonInfoList() {
      this.getSubClassifierById(17).then((response) => {
        this.pss17ReceiptReasonInfoList = response.data.content;
        this.pss17ReceiptReasonInfoList.push({id:null,name:""});
      });
    },

    getPss18TransferReasonInfoList() {
      this.getSubClassifierById(18).then((response) => {
        this.pss18TransferReasonInfoList = response.data.content;
        this.pss18TransferReasonInfoList.push({id:null,name:""});
      });
    },

    getPss19DirectionOfUseInfoList() {
      this.getSubClassifierById(19).then((response) => {
        this.pss19DirectionOfUseInfoList = response.data.content;
        this.pss19DirectionOfUseInfoList.push({id:null,name:""});
      });
    },

    getPss20NeutralizeMethodInfoList() {
      this.getSubClassifierById(20).then((response) => {
        this.pss20NeutralizeMethodInfoList = response.data.content;
        this.pss20NeutralizeMethodInfoList.push({id:null,name:""});
      });
    },

    getPss21BurialObjectInfoList() {
      this.getSubClassifierById(21).then((response) => {
        this.pss21BurialObjectInfoList = response.data.content;
        this.pss21BurialObjectInfoList.push({id:null,name:""});
      });
    },

    getPss22StoredObjectInfoList() {
      this.getSubClassifierById(22).then((response) => {
        this.pss22StoredObjectInfoList = response.data.content;
        this.pss22StoredObjectInfoList.push({id:null,name:""});
      });
    },

    getPss03DangerInfoList() {
      this.getSubClassifierById(3).then((response) => {
        this.pss03DangerInfoList = response.data.content;
      });
    },

    getDangerDegree() {
      this.getSubClassifierById(1).then((response) => {
        this.dangerDegree = response.data.content;
      });
    },

    searchCode(query) {
      this.getCodeList(query);
    },

    searchWasteName(query) {
      this.getWasteNames(query);
    },

    async getCodeList(query) {
      this.search.loadingCodes = true;
      await this.$store.dispatch("getCodeList", query);
      this.search.loadingCodes = false;
    },

    async getWasteNames(query) {
      this.search.loadingNames = true;
      await this.$store.dispatch("getWasteNames", query);
      this.search.loadingNames = false;
    },

    async searchWasteByCode() {
      await this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.code.id
      );
      this.editWasteProductionForm.ss01WasteDictionaryDTO =
        this.foundedWaste.content[0];
    },

    async searchWasteByName() {
      await this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.name.id
      );
      this.editWasteProductionForm.ss01WasteDictionaryDTO =
        this.foundedWaste.content[0];
    },

    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },

    searchWaste() {},

    addWaste() {
      this.clearForm();
      
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("updateOrganizationWaste", this.editWasteProductionForm)
            .then(() => {
              this.notification("Успешно", "Отход изменен", "success");
              this.$router.push({path:"/wasteproduction/main",
              query: { size: this.$route.query.size,page:this.$route.query.page }});
            });
        } else {
          this.notification(
            "Ошибка валидации",
            "Заполните обязательные поля",
            "warning"
          );
        }
      });
    },
    RulsChange(){
      this.rules.pss17ReceiptReasonInfo[0].required = this.editWasteProductionForm.received == null || this.editWasteProductionForm.received == "" ? false : true;
      this.rules.pss17ReceiptReasonInfo2[0].required = this.editWasteProductionForm.received2 == null || this.editWasteProductionForm.received2 == "" ? false : true;
      this.rules.pss17ReceiptReasonInfofz[0].required = this.editWasteProductionForm.receivedfz == null || this.editWasteProductionForm.receivedfz == "" ? false : true;
      this.rules.pss18TransferReasonInfo[0].required = this.editWasteProductionForm.transferred == null || this.editWasteProductionForm.transferred == "" ? false : true;
      this.rules.pss18TransferReasonInfofz[0].required = this.editWasteProductionForm.transferredfz == null || this.editWasteProductionForm.transferredfz == "" ? false : true;
      this.rules.pss21TransferObjectInfo[0].required = this.editWasteProductionForm.pss18TransferReasonInfo.id!=6 || this.editWasteProductionForm.transferred == "" ? false : true;
      this.rules.pss19DirectionOfUseInfo[0].required = this.editWasteProductionForm.used == null || this.editWasteProductionForm.used == "" ? false : true;
      this.rules.pss20NeutralizeMethodInfo[0].required = this.editWasteProductionForm.neutralized == null || this.editWasteProductionForm.neutralized == "" ? false : true;
      this.rules.pss21BurialObjectInfo[0].required = this.editWasteProductionForm.buried == null || this.editWasteProductionForm.buried == "" ? false : true;
      this.rules.pss22StoredObjectInfo[0].required = this.editWasteProductionForm.sentForStorage == null || this.editWasteProductionForm.sentForStorage == "" ? false : true;
      if(this.rules.pss17ReceiptReasonInfo[0].required==false || this.editWasteProductionForm.pss17ReceiptReasonInfo.id == null)
        this.editWasteProductionForm.pss17ReceiptReasonInfo = null;
      if(this.rules.pss17ReceiptReasonInfo2[0].required==false || this.editWasteProductionForm.pss17ReceiptReasonInfo2.id == null)
        this.editWasteProductionForm.pss17ReceiptReasonInfo2 = null;
      if(this.rules.pss17ReceiptReasonInfofz[0].required==false || this.editWasteProductionForm.pss17ReceiptReasonInfofz.id == null)
        this.editWasteProductionForm.pss17ReceiptReasonInfofz = null;
      if(this.rules.pss18TransferReasonInfo[0].required==false || this.editWasteProductionForm.pss18TransferReasonInfo.id == null)
        this.editWasteProductionForm.pss18TransferReasonInfo = null;
      if(this.rules.pss18TransferReasonInfofz[0].required==false || this.editWasteProductionForm.pss18TransferReasonInfofz.id == null)
        this.editWasteProductionForm.pss18TransferReasonInfofz = null;
      if(this.rules.pss21TransferObjectInfo[0].required==false || this.editWasteProductionForm.pss21TransferObjectInfo.id == null)
        this.editWasteProductionForm.pss21TransferObjectInfo = null;
      if(this.rules.pss19DirectionOfUseInfo[0].required==false || this.editWasteProductionForm.pss19DirectionOfUseInfo.id == null)
        this.editWasteProductionForm.pss19DirectionOfUseInfo = null;
      if(this.rules.pss20NeutralizeMethodInfo[0].required==false || this.editWasteProductionForm.pss20NeutralizeMethodInfo.id == null)
        this.editWasteProductionForm.pss20NeutralizeMethodInfo = null;
      if(this.rules.pss21BurialObjectInfo[0].required==false || this.editWasteProductionForm.pss21BurialObjectInfo.id == null)
        this.editWasteProductionForm.pss21BurialObjectInfo = null;
      if(this.rules.pss22StoredObjectInfo[0].required==false || this.editWasteProductionForm.pss22StoredObjectInfo.id == null)
        this.editWasteProductionForm.pss22StoredObjectInfo = null;
    },
    clearForm(){
      if(this.editWasteProductionForm.pss17ReceiptReasonInfo=="") this.editWasteProductionForm.pss17ReceiptReasonInfo = null;
      if(this.editWasteProductionForm.pss17ReceiptReasonInfo2=="") this.editWasteProductionForm.pss17ReceiptReasonInfo2 = null;
      if(this.editWasteProductionForm.pss17ReceiptReasonInfofz=="") this.editWasteProductionForm.pss17ReceiptReasonInfofz = null;
      if(this.editWasteProductionForm.pss18TransferReasonInfo=="") this.editWasteProductionForm.pss18TransferReasonInfo = null;
      if(this.editWasteProductionForm.pss18TransferReasonInfofz=="") this.editWasteProductionForm.pss18TransferReasonInfofz = null;
      if(this.editWasteProductionForm.pss21TransferObjectInfo=="") this.editWasteProductionForm.pss21TransferObjectInfo = null;
      if(this.editWasteProductionForm.pss19DirectionOfUseInfo=="") this.editWasteProductionForm.pss19DirectionOfUseInfo = null;
      if(this.editWasteProductionForm.pss20NeutralizeMethodInfo=="") this.editWasteProductionForm.pss20NeutralizeMethodInfo = null;
      if(this.editWasteProductionForm.pss21BurialObjectInfo=="") this.editWasteProductionForm.pss21BurialObjectInfo = null;
      if(this.editWasteProductionForm.pss22StoredObjectInfo=="") this.editWasteProductionForm.pss22StoredObjectInfo = null;
      if(this.editWasteProductionForm.pss03DangerInfo=="") this.editWasteProductionForm.pss03DangerInfo = {id: null,name: null};
    }
  },

  async mounted() {
    this.prod = Cookies.get('prod');
    this.user_id= Cookies.get('userId');
    this.editWasteProductionForm = this.organizationWaste;
    this.editWasteProductionForm.dateFrom=this.editWasteProductionForm.dateFrom==null?null:new Date(this.editWasteProductionForm.dateFrom);
    this.editWasteProductionForm.dateTo=this.editWasteProductionForm.dateTo==null?null:new Date(this.editWasteProductionForm.dateTo);
    if(this.editWasteProductionForm.organizationWastePSS01DangerDegreeInfo.id==null)
    this.editWasteProductionForm.organizationWastePSS01DangerDegreeInfo=this.editWasteProductionForm.ss01WasteDictionaryDTO.dangerDegreeClassifierDTO;
    this.getPss16PhysicalInfoList();
    this.getPss04SecondaryInfoList();
    this.getPss05MercuryInfoList();
    this.getPss93UnitsInfoList();
    this.getPss17ReceiptReasonInfoList();
    this.getPss18TransferReasonInfoList();
    this.getPss19DirectionOfUseInfoList();
    this.getPss20NeutralizeMethodInfoList();
    this.getPss21BurialObjectInfoList();
    this.getPss22StoredObjectInfoList();
    this.getPss03DangerInfoList();
    this.getDangerDegree();
    this.getOrganizationHierarchyByType();
    this.RulsChange();
    
    console.log(this);
  },
};
</script>

<style>
.el-scrollbar {
  max-width: 70vw !important;
}
</style>
